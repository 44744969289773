import React, { useState } from "react";
import "./Image.css";
import Images from "./Images";

export default function Pictures() {
  const [selectedImg, setSelectedImg] = useState(Images[0]);

  return (
    <div className="pictureContainer">
      <img src={selectedImg} alt="selected" className="york" />
      <div className="imgContainer">
        {Images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt="watch"
            onClick={() => setSelectedImg(img)}
          />
        ))}
      </div>
    </div>
  );
}
