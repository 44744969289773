import React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import Nav from "./components/nav/Nav";
import Slider from "./components/slider/Slider";
import Buy from "./components/buy/buy";
import Invoice from "./components/invoice/Invoice";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route path="/" component={Nav} />
        <Route exact path="/" component={Slider} />
        <Route path="/buy" component={Buy} />
        <Route path="/invoice" component={Invoice} />
      </div>
    </BrowserRouter>
  );
}

export default App;
