import React from "react";
import { Link } from "react-router-dom";

import "./Nav.css";

const Nav = () => {
  return (
    <div className="navContainer">
      <nav className="navbar">
        <Link to="/">
          <h1>Hodlsmith</h1>
        </Link>
        <ul>
          <Link to="/buy">Purchase</Link>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
