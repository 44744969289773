import React from "react";
import "./Invoice.css";

import Footer from "../footer/Footer3";

export default function Invoice() {
  return (
    <div>
      <div>
        <h1 className="text">
          Thank you for ordering. Your invoice is on the way!
        </h1>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
