import silverFront from "../../img/silverFront.jpg";
import blackBack from "../../img/blackBack.jpg";
import blackFront from "../../img/blackFront.jpg";
import giltedCass from "../../img/giltedCass.jpg";
import hodlSide from "../../img/hodlSide.jpg";
import leaCoin from "../../img/leaCoin.jpg";
import liteCoin from "../../img/liteCoin.jpg";
import moneroCoin from "../../img/moneroCoin.jpg";
import silverBand from "../../img/silverBand.jpg";
import blackBand from "../../img/blackBand.jpg";
import gilted1 from "../../img/gilted1.jpg";
import silverSide from "../../img/silverSide.jpg";
import silverTop from "../../img/silverTop.jpg";
import rainbow1 from "../../img/rainbow1.jpg";
import rainbow2 from "../../img/rainbow2.jpg";
import rainbow3 from "../../img/rainbow3.jpg";
import rainbow4 from "../../img/rainbow4.jpg";
import rainbow5 from "../../img/rainbow5.jpg";
import rainbow6 from "../../img/rainbow6.jpg";








export default [
 silverFront,
 blackBack,
 blackFront,
 giltedCass,
 hodlSide,
 leaCoin,
 liteCoin,
 moneroCoin,
 silverBand,
 blackBand,
 gilted1,
 silverSide,
 silverTop,
 rainbow1,
 rainbow2,
 rainbow3,
 rainbow4,
 rainbow5,
 rainbow6
];
