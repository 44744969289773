import React from "react";

import "./Slider.css";

import Footer from "../footer/Footer";

// import styled from "styled-components";
// import { Bitcoin } from "@styled-icons/ionicons-solid/LogoBitcoin";

// const bitcoinSymbol = styled(Bitcoin)`
//   color: red;
// `;

const Slider = () => {
  return (
    <div className="sliderContainer">
      <section>
        <div className="slide">
          <div className="content">
            <h2>Hodlsmith 21 Watch Collection</h2>
            <p>
              The Hodlsmith 21 Watch is a Swiss-engineered timepiece built to
              capture both time and money on the hands of those strong enough to
              hodl it. The watch was crafted to specifically encase physically
              issued and minted Bitcoins and cryptocurrencies.
            </p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h2>A ‘first-ever’ for the Watch world, and the Bitcoin world</h2>
            <p>
              The first time a physical historically-minted Bitcoin has been
              captured in a timepiece. And the first time a watch has physically
              held a numismatic coin in untouched pristine condition.
            </p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h2>Groundbreaking</h2>
            <p>
              The entire watch movement sits on top the case protecting the
              physical coin, encapsulating the ultimate metaphor of the time
              value of money.
            </p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h2>Design Yours</h2>
            <p>
              Design it to your specifications. You decide the color, dial face,
              type of hands, band type, and if you want Holdsmith to load it
              with a physical Bitcoin.
            </p>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Slider;
