import React from "react";
import "./buy.css";
// import Flippy, { FrontSide, BackSide } from "react-flippy";
// import back from "../../img/back.jpg";

// import blackWatch from "../../img/blackWatch.jpg";

import Options from "../options/Options";

import Test from "../footer/Footer2";

// import Carousel from "../carousel/Carousel";
import Images from "../images/Image";

// const FlippyStyle = {
//   width: "200px",
//   height: "300px",
//   textAlign: "center",
//   color: "#FFF",
//   fontFamily: "sans-serif",
//   fontSize: "30px",
//   justifyContent: "center",
// };

// const DefaultCardContents = ({ children }) => (
//   <React.Fragment>
//     <FrontSide
//       style={{
//         backgroundColor: "black",
//         display: "flex",
//         alignItems: "center",
//         flexDirection: "column",
//       }}
//     >
//       <img
//         src={blackWatch}
//         alt="front"
//         style={{ width: "350px", height: "500px" }}
//       />

//       <span
//         style={{
//           fontSize: "12px",
//           position: "absolute",
//           bottom: "-275px",
//           width: "100%",
//         }}
//       >
//         {children}
//         <br />
//       </span>
//     </FrontSide>
//     <BackSide
//       style={{
//         backgroundColor: "black",
//         display: "flex",
//         alignItems: "center",
//         flexDirection: "column",
//       }}
//     >
//       <img src={back} alt="back" style={{ width: "350px", height: "500px" }} />
//       <span
//         style={{
//           fontSize: "12px",
//           position: "absolute",
//           bottom: "-275px",
//           width: "100%",
//         }}
//       >
//         {children}
//         <br />
//       </span>
//     </BackSide>
//   </React.Fragment>
// );

export default class Buy extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isFlipped: false,
  //   };

  //   setInterval(() => {
  //     this.setState({
  //       isFlipped: !this.state.isFlipped,
  //     });
  //   }, 3000);
  // }

  render() {
    return (
      <div className="buyContainer">
        <Images />
        {/* <div
          style={{
            display: "flex",
            flex: "1 0 200px",
            justifyContent: "space-around",
            "flex-wrap": "wrap",
          }}
        >
          <Flippy
            ref={(r) => (this.flippyHorizontal = r)}
            flipOnClick={false}
            style={FlippyStyle}
          >
            <DefaultCardContents>
              <br />
              <button
                type="button"
                onClick={() => this.flippyHorizontal.toggle()}
              >
                Reverse
              </button>
            </DefaultCardContents>
          </Flippy>
        </div> */}
        <div className="row">
          <Options />
        </div>
        <Test />
      </div>
    );
  }
}
