import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footerContainer">
        <div className="copyright">
          <span>Only the strongest hands can hodl.</span>
        </div>
      </div>
    </footer>
  );
}
