import React from "react";
// import { useHistory } from "react-router-dom";
import "./Options.css";


// import axios from "axios";

// .post("https://btcwatches.herokuapp.com/watches"

const Options = (props) => {
  // const [formData, setFormData] = useState({
  //   case_color: "",
  //   contact: "",
  //   is_loaded: "",
  //   band_type: "",
  //   dial_face: "",
  //   hand_type: "",
  // });

  // const updateFormData = (event) => {
  //   console.log("form", formData);
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const history = useHistory();

  // const {
  //   case_color,
  //   contact,
  //   is_loaded,
  //   band_type,
  //   dial_face,
  //   hand_type,
  // } = formData;

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("https://btcwatches.herokuapp.com/watches", {
  //       ...formData,
  //       contact: formData.contact,
  //       case_color: formData.case_color,
  //       is_loaded: formData.is_loaded,
  //       band_type: formData.band_type,
  //       dial_face: formData.dial_face,
  //       hand_type: formData.hand_type,
  //     })
  //     .then((res) => {
  //       console.log("response", res.data);
  //       setFormData(res.data);
  //       history.push("/invoice");
  //     })
  //     .catch((err) => console.log("error", err));
  // };

  return (
    // <form className="test" onSubmit={submitHandler}>
    //   <input
    //     value={contact}
    //     onChange={updateFormData}
    //     placeholder="Contact Email"
    //     type="text"
    //     name="contact"
    //     required
    //   />
    //   <div className="testy">
    //     <div className="selectors">
    //       <select
    //         name="case_color"
    //         value={case_color}
    //         onChange={updateFormData}
    //       >
    //         <option value="0" label="Case Color" />
    //         <option value="Black">Black</option>
    //         <option value="Chocolate">Chocolate</option>
    //         <option value="Silver">Silver</option>
    //         <option value="Blue">Blue</option>
    //       </select>
    //       <select name="is_loaded" value={is_loaded} onChange={updateFormData}>
    //         <option value="0" label="Bitcoin" />
    //         <option value="Gilted Cass">Cassacius Gilted 1 BTC</option>
    //         <option value="Silver Cass">Cassacius Silver 1 BTC</option>
    //         <option value="Lealana">Lealana Silver .5 BTC</option>
    //         <option value="Unloaded">Unloaded</option>
    //       </select>
    //       <select name="band_type" value={band_type} onChange={updateFormData}>
    //         <option value="0" label="Band Type" />
    //         <option value="Leather">Leather</option>
    //         <option value="Metal">Metal</option>
    //       </select>
    //     </div>
    //     <div className="selectors">
    //       <select name="dial_face" value={dial_face} onChange={updateFormData}>
    //         <option value="0" label="Dial Face" />
    //         <option value="Binary">Binary</option>
    //         <option value="Black">Blank</option>
    //       </select>
    //       <select name="hand_type" value={hand_type} onChange={updateFormData}>
    //         <option value="0" label="Type of Hands" />
    //         <option value="Rose">Rose Gold</option>
    //         <option value="Steel">Steel</option>
    //         <option value="Black">Black</option>
    //       </select>
    //     </div>
    //   </div>
    //   <button className="form-submit-button" type="onSubmit">
    //     Submit
    //   </button>
    // </form>
    <h1>Email contact@hodlsmith.com to order.</h1>
  );
};

export default Options;
