import React from "react";
import "./Footer2.css";

export default function Test() {
  return (
    <div className="homeyContainer">
      <div className="copyright">
        <span className="test"> Only the strongest hands can hodl.</span>
      </div>
    </div>
  );
}
