import React from "react";
import "./Footer3.css";

export default function Bottom() {
  return (
    <div className="homeContainer">
      <div className="copyright">
        <span className="test"> Only the strongest hands can hodl.</span>
      </div>
    </div>
  );
}
